* {
  box-sizing: border-box;
}

:root {
  --primary-color: #33bd5e;
  --link-color: darkblue;
  --margin-left: 90px;
  --side-panel-width: 100px;
  --total-width: 580px;
  --default-text: #111;
  /* --grey100: #f1f2f4;
  --grey200: #dfe1e5;
  --grey300: #ccd1d7;
  --grey400: #a8b1bc;
  --grey500: #8892a0;
  --grey600: #6a7585;
  --grey700: #4e5a6a;
  --grey800: #36404e;
  --grey900: #212833; */

  --grey100: #e8f8ed;
  --grey200: #d6ebdd;
  --grey300: #b6d6c3;
  --grey400: #9ec0ab;
  --grey500: #87ab95;
  --grey600: #6e9079;
  --grey700: #506c5b;
  --grey800: #395243;
  --grey900: #293930;
}

.grey100 {
  color: var(--grey100);
}
.grey200 {
  color: var(--grey200);
}
.grey300 {
  color: var(--grey300);
}
.grey400 {
  color: var(--grey400);
}
.grey500 {
  color: var(--grey500);
}
.grey600 {
  color: var(--grey600);
}
.grey700 {
  color: var(--grey700);
}
.grey800 {
  color: var(--grey800);
}
.grey900 {
  color: var(--grey900);
}

.ft-10 {
  font-size: 10px;
}

.ft-11 {
  font-size: 11px;
}

.ft-12 {
  font-size: 12px;
}

.ft-14 {
  font-size: 14px;
}

.ft-16 {
  font-size: 16px;
}

.ft-18 {
  font-size: 18px;
}

.ft-20 {
  font-size: 20px;
}

.ft-24 {
  font-size: 24px;
}

.ft-30 {
  font-size: 30px;
}

.ft-36 {
  font-size: 36px;
}

.ft-48 {
  font-size: 48px;
}

.vertical-center {
  display: flex;
  align-items: center;
}

body,
button {
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-x: hidden;
  color: var(--default-text);
}

h1 {
  font-weight: 300;
  font-size: 23px;
}

.primary {
  color: var(--primary-color);
}

.pointer,
a {
  cursor: pointer;
}

.uppercase {
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.margin-bottom {
  margin-bottom: 40px;
}

.padding-right {
  padding-right: 15px;
}

.right-padding {
  padding-right: 24px;
}
.left-padding {
  padding-left: 24px;
}
.left-spacing {
  padding-left: 12px;
}

.right-spacing {
  padding-right: 12px;
}

.inline-block {
  display: inline-block;
}

.capitalize {
  text-transform: capitalize;
}

.inline {
  display: inline-block;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.normal-weight {
  font-weight: 300;
}

svg {
  overflow: hidden;
}

.overflow-hidden .visualization-layer {
  position: inherit !important;
}

.overflow-visible svg,
.overflow-visible {
  overflow: visible;
}

.flex {
  display: flex;
}

.App {
  width: 100vw;
}

.App-header {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  background: var(--grey600);
  text-transform: uppercase;
}

.bottom-panel {
  bottom: 0px;
  left: 0px;
  padding-left: 24px;
  width: 100%;
  background: white;
  height: 30vh;
  padding-bottom: 10px;
  position: sticky;
  border-top: solid 1px var(--grey800);
}
.bottom-panel.paper {
  box-shadow: none;
}

.App-header h1 {
  margin: 0;
  font-size: 16px;

  color: white;
  font-weight: 500;
  letter-spacing: 3px;
}

.App-header .nav {
  font-size: 15px;
  text-decoration: none;
}

.App-header .nav a {
  text-decoration: none;
  padding-right: 10px;
  font-weight: bold;
  color: black;
}

/* .flex.nav {
  align-content: center;
} */

.page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

img.icon {
  margin-bottom: -3px;
  margin-right: 10px;
  height: 1em;
}

.baseline {
  align-items: center;
}

.logo {
  /* margin-top: 10px; */
  margin-right: 10px;
}

.left-col {
  border-right: 1px solid #ddd;
  width: 30vw;
}

.margin-left {
  margin-left: 10px;
}

.panel {
  /* width: var(--panel-width); */
  /* min-width: 260px; */
  /* width: 30%; */
  width: 25vw;
  border-right: 1px solid #ddd;
  padding-right: 20px;
  padding-left: 25px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.top-panel {
  border-bottom: 1px solid #ddd;
  max-height: 350px;
  overflow: hidden;
}

.panel.large {
  width: 75vw;
}

.panel.left-side {
  padding-left: var(--margin-left);
}

.no-border {
  border: none;
}

.no-margin {
  margin: 0px;
}

.top-border {
  border-top: 1px solid #ddd;
}

.padding {
  padding: 20px;
}

.scroll-y {
  overflow-y: auto;
}

.sticky {
  position: sticky;
  top: 0;
  background: white;
  z-index: 300;
}

.bottom {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

a {
  color: var(--link-color);
}

button {
  background: #ffffff;
  border-radius: 0px;
  border: 2px solid black;
  cursor: pointer;

  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.3em;

  /* font-weight: bold; */
  position: relative;
}

button.import {
  padding: 20px 18px;
}

button:disabled {
  border: 2px solid var(--grey300);
  color: var(--grey300);
}

.upload-files-container button.copy-button,
button.copy-button {
  background-image: url(/img/copy_icon.svg);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: none;
  opacity: 0.2;
  width: 20px;
  float: right;
  height: 20px;
  min-width: 20px;
}

button.copy-button:hover {
  opacity: 0.4;
}

button.clear {
  box-shadow: none;
}

button.good {
  background: var(--primary-color);
  padding: 10px;
  color: white;
}

button.alert {
  color: #ff3733;
}

.resolved-message {
  margin: 10px;
}

.resolved-message,
.resolved-message button {
  font-size: 1.2em;
  font-weight: 300;
}

.col-container {
  display: flex;
}

.col-container div {
  /* flex: 1; */
  /* border-right: solid 2px transparent;
  border-left: solid 2px transparent; */
}

.col-container .col-narrow {
  flex: 0.25;
}

.type-button {
  min-width: 140px;
  display: block;
  /* margin-bottom: 10px; */
  overflow: hidden;
}

.webpack-logo {
  width: 150px;
  margin: -20px -14px;
}

img.rollup-logo {
  margin: -10px 0;
}

img.rome-logo {
  margin-right: 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  zoom: 0.9;
}

img.parcel-logo {
  margin: -10px 0 -10px -2px;
}

button.project-import {
  text-align: left;
  width: 200px;
  height: 70px;
  margin-right: 12px;
  margin-bottom: 12px;
  /* font-size: 17px; */
  display: flex;
  align-items: center;
}

.no-link-underline,
.no-link-underline:hover {
  text-decoration: none;
}

pre {
  margin: 0;
  padding: 0;
  display: block;
  padding: 15px;
  border: solid 1px #ddd;
  position: relative;
}

code {
  font-size: 1em;
  background: #f5f5f5;
}

.add-diff {
  font-weight: bold;
  background: #bcffcf;
}

input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: transparent;
  opacity: 0;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input.search,
button.clear {
  font-size: 15px;
}

.button-import-container {
  display: flex;
  margin-bottom: 13px;
  margin-right: 30px;
}

/* .import-project button {
  margin-top: 10px;
  width: 140px;
} */

.button-import-container button:focus-within,
.project-import:focus-within,
a.active button,
button:focus {
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
  outline: none;
}

.upload-files-container button {
  min-width: 140px;
  height: 40px;
}

.attach-icon {
  opacity: 0.4;
}

.status-icon {
  height: 24px;
  position: relative;
  top: 7px;
  margin-left: 15px;
}

.error {
  background: #ff988b;
  padding: 10px;
  border: solid 2px #c55c5c;
  white-space: pre-wrap;
  font-size: 12px;
}

.resolve-conflicts li {
  font-size: 11px;
}

.resolve-conflicts ul {
  /* height: 600px; */
  overflow: auto;
}

.code-editor {
  width: 400px;
  height: 150px;
}

.opacity-filter.off {
  transition: opacity 0.3s;
  transition-delay: 0.2s;
  opacity: 0;
}

.opacity-filter.on {
  opacity: 0.6;
}

.treemap__node {
  position: absolute;
  border-radius: 2px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(105, 105, 105);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5);
}

.treemap__label {
  box-sizing: border-box;
  font-size: 12px;
  white-space: nowrap;
  padding-left: 4px;
  transform: translate(1px, 1px);
}

.treemap__label--children {
  background-color: rgba(255, 255, 255, 0.15);
}

.treemap__label--children:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}
/* 
table {
  border-spacing: 0px;
}

thead {
  text-align: left;
}

th {
  border-bottom: 1px solid #ccc;
}

th,
td {
  padding: 3px;
} */

.Table {
  width: calc(100% - 16px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
  position: relative;
}

.Table tr:hover {
  background: var(--grey100);
}

.Table tr.selected {
  background: var(--grey200);
}

.Table tr td {
  padding: 4px;
  font-size: 13px;
  padding-bottom: 10px;
}

.Table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.Table thead th.top {
  top: -4px;
  position: sticky;
  z-index: 100;
}

.Table thead th.bottom {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: var(--grey700);
  position: sticky;
  top: calc(30vh + 40px);
  z-index: 100;
  padding: 5px 10px;
}

.Table th,
.Table td {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.Table tbody td {
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
}

.Table tr.selected .name {
  font-weight: 700;
}

.Table th {
  font-size: 14px;
  background: var(--grey100);
}

.bar-chart .fixed-label {
  font-size: 12px;
  position: absolute;
  transform: translateX(-100%);
  white-space: nowrap;
}

.header-link,
.header-link:visited,
.header-link:hover {
  align-items: center;
  color: var(--default-text);
  text-decoration: none;
}

.import-asset {
  display: flex;
  align-items: center;
}
.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0 %,
  100 % {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .github - corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

.alpha-warning {
  margin-left: 20px;
  background: var(--grey400);
  padding: 4px;
  color: var(--grey800);
}

.button-wrap {
  display: inline-block;
}

.button-wrap button {
  display: inline-block;
}

.import-instruction {
  flex-direction: column;
}

.uppercase-header {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}

.paper {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
